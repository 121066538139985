



































































































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { metadata } from "@/plugins/metadata";
import { types } from "@/services/westore/enums";
import wsCommonForm from "@/components/ws-common-form.vue";
import themeCard from "@/components/ws-theme-card.vue";
 
export default mixins(MyMixin).extend({
  name: "Theme3",
  components: { wsCommonForm, themeCard },
  props: ["item"],
  metaInfo() {
    return {
      title: this.item.name,
      htmlAttrs: {
        lang: "en-US",
      },
      meta: [
        { charset: "utf-8" },
        { name: "robots", content: "follow, index" },
        {
          name: "description",
          content: this.item.description,
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1, user-scalable=no",
        },
      ],
    };
  },
  mounted() {
    this.trackMyPageView();
  },
  computed: {
    types() {
      return types;
    },
  },
  data: () => ({
    isShow: false,
    showVideo: false,
  }),
});
