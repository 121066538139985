<template>
  <div class="services mb-4" :style="{ background: `url(${serviceImg})` }">
    <h2 class="service-title">{{ title }}</h2>
    <ul class="service-list">
      <li v-for="service in services" :key="service">{{ service }}</li>
    </ul>
  </div>
</template>
<script>
export default {
  props: ["title", "services", "serviceImg"],
};
</script>

<style lang="scss" scope>
.services {
  position: relative;
  padding: 0px 20px 0;
  z-index: 1;
  .service-list {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      position: relative;
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      text-transform: capitalize;
      color: #101820;
      padding-left: 1em;
      &::before {
        content: "•";
        color: #48beea;
        position: absolute;
        left: -1px;
        top: 1px;
        font-size: 19px;
      }
    }
  }
}
</style>
