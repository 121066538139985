



































































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { metadata } from "@/plugins/metadata";
import { types } from "@/services/westore/enums";
import wsCommonForm from "@/components/ws-common-form.vue";
import themeCard from "@/components/ws-theme-card.vue";
import themeService from "@/components/ws-theme-service.vue";
import Modal from "@/components/ws-theme-modal.vue";

export default mixins(MyMixin).extend({
  name: "Theme1",
  components: { wsCommonForm, themeCard, themeService, Modal },
  props: ["item"],
  metaInfo() {
    return {
      title: this.item.name,
      htmlAttrs: {
        lang: "en-US",
      },
      meta: [
        { charset: "utf-8" },
        { name: "robots", content: "follow, index" },
        {
          name: "description",
          content: this.item.description,
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1, user-scalable=no",
        },
      ],
    };
  },
  mounted() {
    this.trackMyPageView();
  },
  computed: {
    types() {
      return types;
    },
  },
  methods: {
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
  data: () => ({
    isModalVisible: false,
    swiperOptions: {
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
      },
    },
  }),
});
