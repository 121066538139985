














































































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { metadata } from "@/plugins/metadata";
import { types } from "@/services/westore/enums";
import wsCommonForm from "@/components/ws-common-form.vue";
import themeCard from "@/components/ws-theme-card.vue";
import themeService from "@/components/ws-theme-service.vue";

export default mixins(MyMixin).extend({
  name: "Theme2",
  components: { wsCommonForm, themeCard, themeService },
  props: ["item"],
  metaInfo() {
    return {
      title: this.item.name,
      htmlAttrs: {
        lang: "en-US",
      },
      meta: [
        { charset: "utf-8" },
        { name: "robots", content: "follow, index" },
        {
          name: "description",
          content: this.item.description,
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1, user-scalable=no",
        },
      ],
    };
  },
  data: () => ({
    isShow: false,
    showBox: false,
    btnColor: "ws-primary",
    btnBG: "white",
    btnTitle: "Discover more",
  }),
  mounted() {
    this.trackMyPageView();
    if (window.matchMedia("screen and (min-width: 769px)").matches) {
      this.btnBG = "#48BEEA";
      this.btnColor = "white";
      this.btnTitle = "Watch A Video";
    }
  },
  computed: {
    types() {
      return types;
    },
  },
  methods: {
    toggleShow() {
      this.showBox = !this.showBox;
    },
  },
});
