






















import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { GetLanding } from "@/integration/get-landing";
import Theme1 from "../new-landing/theme1.vue";
import Theme2 from "../new-landing/theme2.vue";
import Theme3 from "../new-landing/theme3.vue";
import axios from "axios";

export default mixins(MyMixin).extend({
  name: "new-landing",
  components: { Theme1, Theme2, Theme3 },
  mounted() {
    this.fetchData();
  },
  data: () => ({
    item: {},
  }),
  methods: {
    fetchData() {
      axios
        .get(
          `https://staging.admin.westore.ai/api/landing-pages/${this.$route.params.slug}`
        )
        .then(({ data }) => {
          this.item = data.data;
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "404" });
        });
    },
  },
});
