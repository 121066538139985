<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click="close">
      <div class="modal" @click.stop="">
        <div class="close-btn" @click="close">
          <span> &times;</span>
        </div>
        <!--Start-Modal-Header-->
        <header class="modal-header">
          <div
            class="d-flex justify-content-center align-items-center flex-column mt-5"
          >
            <v-img
              height="32"
              width="142"
              :src="require('@/assets/leads/Logo.svg')"
              alt="logo"
              contain
            />
            <h4>WELCOME TO WESTORE!</h4>
          </div>
        </header>
        <!--Start-Modal-Body-->
        <div class="modal-body p-0">
          <slot name="modal-body"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  background: rgba(16, 24, 32, 0.5);
  backdrop-filter: blur(3px);
  opacity: 1;
  display: flex;
  align-items: center;
  .modal {
    width: 90%;
    margin: 0 auto 20px;
    right: 0;
    top:unset;
    overflow-y: auto;
    height: fit-content;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 2px 16px 16px #1018200d;
    border-radius: 16px;
    z-index: 999;
    .close-btn {
      position: absolute;
      right: 15px;
      top: 10px;
      border: 1px solid #9aa5b0;
      width: 16px;
      height: 16px;
      line-height: 11px;
      border-radius: 2px;
      text-align: center;
      font-size: 18px;
      color: #9aa5b0;
      font-weight: 600;
      z-index: 99;
      cursor: pointer;
    }
    .modal-header {
      border: none;
      padding: 20px 25px 0;
      justify-content: center;
      h4 {
        font-family: "Lato";
        font-size: 20px;
        font-weight: 600;
        margin-top: 15px;
      }
    }
  }
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.4s ease;
}
</style>
