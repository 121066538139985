<template>
  <div class="know-more" :style="{ background: `${bgColor}` }">
    <div>
      <h4 :style="{ color: `${titleColor}` }">{{ title }}</h4>
      <p class="px-10" :style="{ color: `${subTitleColor}` }">
        {{ subTitle }}
      </p>
    </div>
    <v-btn
      large
      block
      elevation="0"
      class="mb-2 mt-4 submit-btn"
      @click="$emit('discoverMore')"
      :style="{ color: `${btnColor}`, background: `${btnBG}` }"
    >
      {{ btnTitle }}
      <v-icon class="hidden-md-and-down ml-2" size="18" style="margin-top: 2px"
        >$vuetify.icons.playIcon</v-icon
      >
    </v-btn>
  </div>
</template>
<script>
export default {
  props: [
    "bgColor",
    "title",
    "subTitle",
    "btnTitle",
    "titleColor",
    "subTitleColor",
    "btnColor",
    "btnBG",
  ],
};
</script>
